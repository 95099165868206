export type BackoffOptions = {
  initialBackoff?: number
  backoffRate?: number
  maxWait?: number
}

const DEFAULT_BACKOFF_OPTIONS = {
  initialBackoff: 100,
  backoffRate: 1.5,
  maxWait: 10000,
}

export const whenAvailable = (
  test: () => boolean,
  callback: () => void,
  options: BackoffOptions = DEFAULT_BACKOFF_OPTIONS
) => {
  options = { ...DEFAULT_BACKOFF_OPTIONS, ...options }
  function when(backoff) {
    backoff = Math.min(backoff || 1, options.maxWait)
    if (test()) {
      callback()
    } else {
      setTimeout(function () {
        when(backoff * options.backoffRate)
      }, backoff)
    }
  }
  when(options.initialBackoff)
}
