import Cookie from 'js-cookie'
import partial from 'lodash/partial'
import getConfig from '@/config'

import * as output from '../../lib/console'
import '../../lib/date.iso8601'

import callAPI from '../api/call'

const getTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (e) {
    return ''
  }
}

const config = getConfig().publicRuntimeConfig
const IS_LOGGING =
  config.debugLog ||
  (global.document && /debugLog/.test(global.document.location.search))

const log = (service, ...args) => {
  if (IS_LOGGING) {
    output.log(service, ...args)
  }
}

const ignoreError = f => {
  return (...args) => {
    try {
      return f(...args)
    } catch (e) {
      output.error(e)
    }
  }
}

interface Tracker {
  jwt(jwt: string): void
  tokens(tokens: { csrf_token?: string; client_token?: string }): void
  track(event_name: string, properties?: any): void
  register(properties: any): void
  identify(user_id: string): void
  alias(user_id: string): void
}

interface TrackerConfig {
  url: string
}

class ConcreteTracker implements Tracker {
  config: TrackerConfig
  _jwt?: string
  _tokens: { csrf_token?: string; client_token?: string }
  user_id?: string
  user_properties?: any
  request?: any
  interval?: any

  constructor(config: TrackerConfig) {
    this.config = config
    this._tokens = {}
    this.setupRequest()
    this._jwt = null

    if (window?.addEventListener) {
      window.addEventListener('unload', () => {
        this.save()
      })
    }
    if (document?.addEventListener) {
      document.addEventListener('visibilitychange', () => {
        if (
          document.visibilityState === 'hidden' &&
          document.readyState !== 'loading'
        ) {
          this.save()
        }
      })
    }
  }

  setupRequest() {
    this.request = {
      method: 'POST',
      url: this.config.url.replace(/\/+$/, '') + '/in',
      headers: {},
      json: {
        events: [],
      },
    }
    const jwt = this._jwt
    if (jwt) {
      this.request.headers['authorization'] = 'Bearer ' + jwt
    }
    if (this.user_id) {
      this.request.headers['x-meds-userid'] = this.user_id
    }
    if (this._tokens.client_token) {
      this.request.headers['x-meds-client-token'] = this._tokens.client_token
    }
    const tz = Cookie.get('mstz') || getTimezone()
    if (tz) {
      this.request.headers['x-meds-tz'] = tz
    }
    if (this.user_properties) {
      this.request.json.user_properties = this.user_properties
    }
  }

  jwt(jwt) {
    this._jwt = jwt
  }

  tokens(tokens) {
    this._tokens = tokens
  }

  alias(user_id) {
    this.user_id = user_id
  }

  identify(user_id) {
    this.user_id = user_id
  }

  register(properties) {
    this.user_properties = properties
    this.request.json.user_properties = properties
  }

  track(event_name, properties) {
    this.request.json.events.push({
      name: event_name,
      date: new Date().toISOString(),
      extra: properties || {},
    })
    this.save()
  }

  save() {
    const realSave = () => {
      const request = this.request
      this.setupRequest()
      this.postRequest(request)
      this.interval = null
    }
    if (!!this._tokens.client_token && !!this._jwt) {
      realSave()
    } else if (!this.interval) {
      this.interval = setTimeout(() => this.save(), 2000)
    }
  }

  postRequest(request) {
    if (request.json.events.length > 0) {
      const jwt = this._jwt
      if (jwt) {
        request.headers['authorization'] = 'Bearer ' + jwt
      }
      if (this._tokens.client_token) {
        request.headers['x-meds-client-token'] = this._tokens.client_token
      }
      callAPI(request)
        .then(res => {})
        .catch(error => {
          output.error(error)
        })
    }
  }
}

export const createMedShrTracker = (config: TrackerConfig): Tracker => {
  if (config && config.url) {
    return new ConcreteTracker(config)
  }
  return {
    jwt: partial(log, 'MedShr JWT:'),
    tokens: partial(log, 'MedShr Auth Tokens:'),
    track: partial(log, 'MedShr:'),
    register: partial(log, 'MedShr register:'),
    identify: partial(log, 'MedShr identify:'),
    alias: partial(log, 'MedShr alias:'),
  }
}
