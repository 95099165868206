import * as types from '../modules/entities/types'

export default {
  feed: {
    [types.FEED_MAIN_CONTENT]: '/cases',
    [types.FEED_NEWEST_CONTENT]: '/cases/latest',
  },
  [types.USER]: {
    [types.FEED_MY_CASES_WITH_UNREAD_COMMENTS]: '/members/:id/cases',
    [types.FEED_MY_DRAFT_CASES]: '/members/:id/cases',
    [types.FEED_MY_PUBLISHED_CASES]: '/members/:id/cases',
    [types.FEED_MY_FOLLOWING_CASES]: '/members/:id/cases',

    [types.FEED_USER_CASES]: '/members/:id/cases',

    [types.FEED_USER_GROUPS]: '/members/:id/groups',
    [types.FEED_USER_SUGGESTED_GROUPS]: '/members/:id/groups/suggested',
  },
  [types.GROUP]: {
    [types.FEED_GROUP_CASES]: '/groups/:id/cases',
    [types.FEED_GROUP_CASES_FOR_FEATURING]: '/groups/:id/cases',
  },
  [types.INSTITUTION]: {
    [types.FEED_INSTITUTION_CASES]: '/institutions/:id/cases',
  },
}
