let defaultCookies =
  typeof global.document === 'undefined' ? '' : global.document.cookie

export const setDefaultCookieString = (cookies: string) =>
  (defaultCookies = cookies)

const getCookieString = () =>
  typeof global.document === 'undefined'
    ? defaultCookies
    : global.document.cookie

export const useCookieString = getCookieString

export const getCookie = (key: string): string => {
  const m = useCookieString()?.match(new RegExp(`\\b${key}=([^;]+)`))
  if (m) {
    return decodeURIComponent(m[1])
  }
  return undefined
}

export const useCookie = getCookie
