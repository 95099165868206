import * as Sentry from '@sentry/nextjs'
import getConfig from '@/config'
import { path } from 'ramda'
import { base64encode } from '../../lib/base64'
import * as output from '../../lib/console'
import { whenAvailable } from '../../lib/when-available'
import { isPrintingToPDF } from '../../lib/client-info'

const config = getConfig().publicRuntimeConfig
const IS_LOGGING =
  config.debugLog ||
  (global.document && /debugLog/.test(global.document.location.search))

const disableTracking =
  typeof window !== 'undefined' &&
  window['navigator'].userAgent &&
  /(medshr.net test runner|burpsuite|zaproxy)/i.test(
    window['navigator']['userAgent']
  )

const branchKey = path(['branch', 'key'], config)
const branchURL = path(['branch', 'url_prefix'], config)

export type BranchAPI = {
  track: (
    event: string,
    properties: Record<string, string | number | boolean>
  ) => void
  data: () => Record<string, string | number | boolean>
  setIdentity: (id: string) => void
  logout: () => void
  link: (params: any) => Promise<string>
  longLink: (params: any) => Promise<string>
}

let _branch: BranchAPI = undefined
let _branchData: Record<string, string | number | boolean> = undefined

export const getBranch = (): Promise<BranchAPI> =>
  _branch
    ? Promise.resolve(_branch)
    : new Promise((resolve, reject) => {
        if (branchKey && !isPrintingToPDF() && !disableTracking) {
          whenAvailable(
            () => Boolean(path(['branch'], global)),
            () => {
              if (config.debug && IS_LOGGING) log('init branch')
              const branch = path(['branch'], global)
              _branch = wrapBranch(branch)
              branch.init(branchKey, function (err, data) {
                if (err) {
                  reject(err)
                  return
                }
                if (data) {
                  if (config.debug) log('branch data', data)
                  setBranchData(data)
                }
                resolve(_branch)
              })
            },
            {
              maxWait: 5000,
            }
          )
        } else {
          _branch = dummyBranch()
          resolve(_branch)
        }
      })

const setBranchData = data => (_branchData = data)

const log = (service, ...args) => {
  if (IS_LOGGING) output.log(service, ...args)
  Sentry.addBreadcrumb({
    message: service,
    data: args || {},
  })
}

const fixupParameters = params => {
  if (typeof params['~channel'] === 'undefined' && params.channel) {
    params['~channel'] = params.channel
  }
  if (typeof params['~feature'] === 'undefined' && params.feature) {
    params['~feature'] = params.feature
  }
  return params
}

const wrapBranch = branch => ({
  track: branch.track.bind(branch),
  data: () => _branchData,
  setIdentity: branch.setIdentity.bind(branch),
  logout: branch.logout.bind(branch),
  link: (params: any): Promise<string> =>
    new Promise((resolve, reject) => {
      branch.link({ data: fixupParameters(params) }, (err, url) => {
        if (url) {
          resolve(url)
        } else {
          longLink(params).then(resolve).catch(reject)
        }
      })
    }),
  longLink,
})

const longLink = (params: any): Promise<string> =>
  new Promise((resolve, reject) => {
    if (branchURL && branchKey) {
      const url =
        branchURL.replace(/\/+$/, '') +
        '/a/' +
        branchKey +
        '?data=' +
        base64encode(JSON.stringify(fixupParameters(params)))
      resolve(url)
    } else {
      reject(new Error('Branch URL not configured'))
    }
  })

const dummyBranch = () => ({
  track: (...args) => log('Branch:', ...args),
  data: () => _branchData,
  logout: () => undefined,
  setIdentity: (...args) => log('Branch setIdentity:', ...args),
  link: longLink,
  longLink,
})
