import { useCookie } from './use-cookies'

export const isPrintingToPDF = () =>
  global['location'] &&
  global['location']['search'] &&
  /pdf=1/.test(global['location']['search'])

export const isNativeAppBrowser = () =>
  /^(ms|medshr)_(ios|android)/.test(useCookie('client_type') ?? 'ms_web')

export const isNativeAppBrowserAsync = async () =>
  /^(ms|medshr)_(ios|android)/.test(useCookie('client_type') ?? 'ms_web')

export const isNativeiOSAppBrowser = () =>
  /^(ms|medshr)_ios/.test(useCookie('client_type') ?? 'ms_web')
