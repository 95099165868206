export const USER = 'user'
export const CASE = 'case'
export const AD_CARD = 'ad_card'
export const GROUP = 'group'
export const INSTITUTION = 'institution'
export const GROUP_PERMISSIONS = 'group_permissions'
export const PINNED_MESSAGE = 'pinned_message'

export const POLL = 'poll'

export const LEARNING_SERIES = 'learning_series'
export const LEARNING_MODULE = 'learning_module'
export const DOCSEARCH_SAVED_SEARCH = 'docsearch_saved_search'

export const ENTRY_LEARNING_SERIES = 'entry_learning_series'
export const ENTRY_LEARNING_MODULE = 'entry_learning_module'
export const ENTRY_DOCSEARCH_SAVED_SEARCH = 'entry_docsearch_saved_search'

export const ENTRY_UPDATE = 'entry_update'
export const ENTRY_USER = 'entry_user'
export const ENTRY_CASE = 'entry_case'
export const ENTRY_AD_CARD = 'entry_ad_card'
export const ENTRY_GROUP = 'entry_group'
export const ENTRY_INSTITUTION = 'entry_institution'
export const ENTRY_SEARCH_RESULT = 'search_result'
export const ENTRY_GROUP_MEMBER = 'entry_group_member'
export const ENTRY_PINNED_MESSAGE = 'entry_pinned_message'

export const FEED_MAIN_CONTENT = 'feed'
export const FEED_MAIN_UPDATES = 'updates'
export const FEED_CONNECTION_REQUESTS = 'requests'
export const FEED_USER_CONNECTION_REQUESTS = 'user_requests'
export const FEED_GROUP_CONNECTION_REQUESTS = 'group_requests'
export const FEED_GROUPS_ADMIN = 'groups_admin'
export const FEED_GROUPS_ACTIVITY = 'groups_activity'
export const FEED_NEWEST_CONTENT = 'newest'
export const FEED_MY_CONTENT = 'my'
export const FEED_MY_CASES = 'my_cases'
export const FEED_USER_CASES = 'user_cases'
export const FEED_USER_GROUPS = 'user_groups'
export const FEED_USER_SUGGESTED_GROUPS = 'user_suggested_groups'
export const FEED_GROUP_CASES = 'group_cases'
export const FEED_GROUP_FEATURED_CASES = 'group_featured_cases'
export const FEED_GROUP_CASES_FOR_FEATURING = 'group_cases_for_feature'
export const FEED_GROUP_FEATURED_LEARNING = 'group_featured_learning'
export const FEED_GROUP_ADMIN = 'group_admin'
export const FEED_GROUP_ACTIVITY = 'group_activity'
export const FEED_INSTITUTION_CASES = 'institution_cases'
export const FEED_INSTITUTION_FEATURED_CASES = 'institution_featured_cases'
export const FEED_INSTITUTION_ADMIN = 'institution_admin'
export const FEED_INSTITUTION_ACTIVITY = 'institution_activity'
export const FEED_SEARCH_RESULTS = 'search_results'
export const FEED_GROUP_MEMBER_SEARCH_RESULTS = 'group_member_search_results'
export const FEED_SIMILAR_CASES = 'similar_cases'
export const FEED_DISCUSSION = 'discussion'

export const FEED_MY_DRAFT_CASES = 'draft_cases'
export const FEED_MY_PUBLISHED_CASES = 'published_cases'
export const FEED_MY_FOLLOWING_CASES = 'following_cases'
export const FEED_MY_CASES_WITH_UNREAD_COMMENTS = 'cases_unread'

// used by analytics
export const SOURCE_FEED_NAMES = {
  [FEED_MAIN_CONTENT]: 'Case feed',
  [FEED_DISCUSSION]: 'Case feed',
  [FEED_NEWEST_CONTENT]: 'Case feed',
  [FEED_SIMILAR_CASES]: 'Case feed',
  [FEED_USER_CASES]: "Someone else's profile",
  [FEED_GROUP_CASES]: 'Case feed',
  [FEED_GROUP_CASES_FOR_FEATURING]: 'Case feed',
  [FEED_INSTITUTION_CASES]: 'Case feed',
}

const caseFeedMap = {
  [USER]: FEED_USER_CASES,
  [GROUP]: FEED_GROUP_CASES,
  [INSTITUTION]: FEED_INSTITUTION_CASES,
}

const seriesFeedMap = {
  [GROUP]: FEED_GROUP_FEATURED_LEARNING,
}

export function getFeedTypeForEntitySeries(entityType) {
  return seriesFeedMap[entityType]
}

export function getFeedTypeForEntityCases(entityType) {
  return caseFeedMap[entityType]
}

const entryMap = {
  [USER]: ENTRY_USER,
  [CASE]: ENTRY_CASE,
  [AD_CARD]: ENTRY_AD_CARD,
  [GROUP]: ENTRY_GROUP,
  [INSTITUTION]: ENTRY_INSTITUTION,
  [PINNED_MESSAGE]: ENTRY_PINNED_MESSAGE,
  [LEARNING_MODULE]: ENTRY_LEARNING_MODULE,
  [LEARNING_SERIES]: ENTRY_LEARNING_SERIES,
  [DOCSEARCH_SAVED_SEARCH]: ENTRY_DOCSEARCH_SAVED_SEARCH,
}

const entityMap = {
  [ENTRY_USER]: USER,
  [ENTRY_CASE]: CASE,
  [ENTRY_AD_CARD]: AD_CARD,
  [ENTRY_GROUP]: GROUP,
  [ENTRY_INSTITUTION]: INSTITUTION,
  [ENTRY_GROUP_MEMBER]: USER,
  [ENTRY_PINNED_MESSAGE]: PINNED_MESSAGE,
  [ENTRY_LEARNING_MODULE]: LEARNING_MODULE,
  [ENTRY_LEARNING_SERIES]: LEARNING_SERIES,
  [ENTRY_DOCSEARCH_SAVED_SEARCH]: DOCSEARCH_SAVED_SEARCH,
}

export function getEntryTypeForEntityType(entityType) {
  return entryMap[entityType]
}

export function getEntityTypeForEntryType(entryType) {
  if (entityMap[entryType]) {
    return entityMap[entryType]
  }

  for (const entityType in entryMap) {
    if (entryMap[entityType] === entryType) {
      return entityType
    }
  }
  return undefined
}
