import Router, { useRouter } from 'next/router'
import { decode } from 'querystring'
import { last, path } from 'ramda'
import filter from 'lodash/filter'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import fromPairs from 'lodash/fromPairs'
import isArray from 'lodash/isArray'
// /////////////////////////////////////////////////////////////////////// ROUTE
import urlmap from './routes/reverse'
// ////////////////////////////////////////////////////////////////////// CONFIG
import getConfig from './config'

import { whenPath } from './utils/whenPath'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export const getQueryString = (): string => path(['location', 'search'], global)
export const getQuery = (): any => urldecode(getQueryString())

const internalURL = new RegExp(
  `^(/[^/]|https?://([^\.]+\.)?(${
    getConfig().publicRuntimeConfig.baseHostName
  }|medshr\.it)/)`
)

export const isExternalURL = (url: string): boolean => !internalURL.test(url)

export const getParameter = (
  query: { [k: string]: string | string[] },
  k: string,
  defaultValue?: string
) => {
  const value = query[k]
  if (value !== undefined) {
    if (typeof value === 'string') {
      return value
    }
    if (value.length > 0) {
      return value[0]
    }
  }
  return defaultValue
}

export const useParameter = (k: string, defaultValue?: string) => {
  const router = useRouter()
  return getParameter(router.query, k, defaultValue)
}

export const redirectAuthCheck = (): void => {
  whenPath(
    ['document', 'location'],
    (location: Location) => {
      location.href = '/auth/check?next=' + encodeURIComponent(location.href)
    },
    global
  )
}

class ResourceContextParameters {
  pt?: string
  p?: string
  ft?: string
  f?: string
}

class ResourceContext {
  type?: string
  id?: string
  feed_type?: string
  feed_id?: string
}

// ///////////////////////////////////////////////////////////////// QUALIFY URL
export function qualifyUrl(url: string | { hostName: string; path: string }) {
  if (typeof url !== 'string') {
    return `https://${url.hostName}${url.path}`
  }

  if (/^https?:\/\//.test(url)) {
    return url
  }
  if (/^\/api\//.test(url)) {
    return getConfig().publicRuntimeConfig.api.url + url
  }
  if (
    typeof global['document'] !== 'undefined' &&
    global['document']['location']
  ) {
    const location: Location = global['document']['location']
    return location.href.replace(/^(https?:\/\/[^\/]+).*/, '$1') + url // eslint-disable-line
  }
  return url
}

// /////////////////////////////////////////////////////////////// GET ADMIN URL
export function getAdminURL(user, path) {
  const queryString =
    whenPath(
      ['document', 'location'],
      (location: Location) =>
        '?referer=' + encodeURIComponent(location.pathname + location.search),
      global
    ) || ''
  return `${user.admin_base_url}/${path}${queryString}`
}

// /////////////////////////////////////////////////////////////// GET ADMIN URL
export function getCMSURL(user, path) {
  const queryString =
    whenPath(
      ['document', 'location'],
      (location: Location) => '?referer=' + encodeURIComponent(location.href),
      global
    ) || ''
  return `${getConfig().publicRuntimeConfig.cmsUrl}/${path}${queryString}`
}

export function getImageURL(size, bucket, path) {
  return `${
    getConfig().publicRuntimeConfig.imagesUrl
  }/${bucket}/${size}/${path}`
}

// /////////////////////////////////////////////////////////////// GET DASHBOARD URL
export function getDashboardURL(subpath) {
  return `${getConfig().publicRuntimeConfig.dashUrl}/${subpath.replace(
    /^\/+/,
    ''
  )}`
}

// ///////////////////////////////////////////////////////// APPLY SUBSTITUTIONS
export function applySubstitutions(url, params) {
  return reduce(
    params,
    (url, v, k) => {
      if (isArray(v)) {
        return reduce(
          v,
          (url, x) => url.replace(`:${k}`, x).replace(`[${k}]`, x),
          reduce(v, (url, x, i) => url.replace(`:${k}[${i}]`, x), url)
        )
      }

      return url.split(`:${k}`).join(v).split(`[${k}]`).join(v)
    },
    url
  )
}

// ////////////////////////////////////////////////////////////////// URL ENCODE
export function urlencode(o) {
  return filter(
    map(o, (v, k) => {
      if (v !== undefined) {
        if (Array.isArray(v)) {
          return map(
            v,
            v => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
          ).join('&')
        }
        return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      }
    }),
    v => v !== undefined
  ).join('&')
}

// ////////////////////////////////////////////////////////////////// URL DECODE
export function urldecode(str) {
  return str
    ? fromPairs(
        map(
          (str.length && str[0] === '?' ? str.substring(1) : str).split('&'),
          v => v.split('=')
        )
      )
    : {}
}

// //////////////////////////////////////////////////////////////// APPEND QUERY
export function appendQuery(url, query) {
  const q = typeof query === 'string' ? query : urlencode(query)
  return url + (url.lastIndexOf('?') === -1 ? '?' : '&') + q
}

// ///////////////////////////////////////////////////// GET BACK URL FROM QUERY
export function getBackURLFromQuery(query) {
  const context = getContextFromQuery(query)
  let tpl = urlmap

  if (context.type) {
    tpl = tpl[context.type]
  }
  if (context.feed_type) {
    tpl = tpl[context.feed_type]
  }
  if (typeof tpl === 'object') {
    tpl = tpl['_']
  }
  if (typeof tpl === 'string') {
    return applySubstitutions(tpl, context)
  }
}

export function getContextFromQuery(
  query: ResourceContextParameters = {}
): ResourceContext {
  const { pt, p, ft, f } = query
  const resourceContext = new ResourceContext()
  if (pt !== undefined) {
    resourceContext.type = getResourceTypeForURLName(pt)
  }
  if (p !== undefined) {
    resourceContext.id = p
  }
  if (ft !== undefined) {
    resourceContext.feed_type = ft
  }
  if (f !== undefined) {
    resourceContext.feed_id = f
  }

  return resourceContext
}

function getContextQueryString(context: ResourceContext) {
  if (!context) {
    context = decode(last(Router.asPath.split('?')))
  }
  if (context && (context.type || context.feed_type)) {
    const { type, id, feed_type, feed_id } = context
    return `?${urlencode({
      pt: getURLNameForResourceType(type),
      p: id,
      ft: feed_type,
      f: feed_id,
    })}`
  }

  return ''
}

function getURLNameForResourceType(type: string): string {
  switch (type) {
    case 'case':
      return 'cases'
    case 'user':
      return 'members'
    case 'group':
      return 'groups'
    case 'institution':
      return 'institutions'
    case 'feed':
      return 'feed'

    case 'post-category':
      return 'news/category'

    case 'post-tag':
      return 'news/tag'

    case 'post':
      return 'open'

    case 'page':
    case 'pages':
      return 'pages'

    default:
      console.log(`Unexpected resource type ${type}`)
      throw new Error(`Unexpected resource type ${type}`)
  }
}

function getResourceTypeForURLName(name: string): string {
  switch (name) {
    case 'cases':
      return 'case'
    case 'members':
      return 'user'
    case 'groups':
      return 'group'
    case 'institutions':
      return 'institution'
    case 'feed':
      return 'feed'
    default:
      throw new Error(`Unexpected URL part ${name}`)
  }
}

// //////////////////////////////////////////////////////////// URL FOR RESOURCE

export function link_for_resource(
  resource: { type: string; id: string },
  subpage?: string,
  opt_context?: ResourceContext
): { to: string; as: string }
export function link_for_resource(
  resourceType: string,
  resourceID: string,
  subpage?: string,
  opt_context?: ResourceContext
): { to: string; as: string }

export function link_for_resource(
  resourceType: string | { type: string; id: string },
  resourceID?: string,
  subpage?: string | ResourceContext,
  opt_context?: ResourceContext
): { to: string; as: string } {
  let rt, ri, sp, c
  if (typeof resourceType === 'string') {
    rt = resourceType
    ri = resourceID
    sp = subpage
    c = opt_context
  } else {
    rt = resourceType.type
    ri = resourceType.id
    sp = resourceID
    c = subpage
  }
  const as =
    url_for_resource(rt, ri, c) + (sp ? (sp[0] === '/' ? sp : '/' + sp) : '')
  const to = route_for_resource_type(rt, sp)
  return { as, to }
}

export function route_for_resource_type(
  resource_type: string,
  subpage?: string
): string {
  return (
    `/${getURLNameForResourceType(resource_type)}/[id]` +
    (subpage ? (subpage[0] === '/' ? subpage : '/' + subpage) : '')
  )
}

export function url_for_resource(
  resource_type: string,
  resource_id: string,
  opt_context?: ResourceContext
): string {
  if (opt_context) {
    return (
      url_for_resource(resource_type, resource_id) +
      getContextQueryString(opt_context)
    )
  }

  return `/${getURLNameForResourceType(resource_type)}/${encodeURIComponent(
    resource_id
  )}`
}

// ///////////////////////////////////////////////////////////////////// API URL
export function api_url(path: string): string {
  return getConfig().publicRuntimeConfig.api.url + path
}

// ///////////////////////////////////////////////////////////////////// LIVE URL
export function rtmp_url(path: string): string {
  return getConfig().publicRuntimeConfig.rtmpsUrl + path
}
