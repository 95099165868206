import * as console from '../../lib/console'
import { qualifyUrl } from '../../url'
import { toPairs, reduce } from 'ramda'
import getConfig from '@/config'

const config = getConfig().publicRuntimeConfig

const cancellableRequests = {}
const verbose = false

export function commonRequestHeaders(): { [k: string]: string } {
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
  }
  if (typeof window === 'undefined') {
    headers['user-agent'] = `medshr.net frontend/${config.version}`
  }
  return headers
}

export type URLType = string | { hostName: string; path: string }

type Request = {
  method?: string
  url: URLType
  headers?: { [k: string]: string }
  json?: Object | JSON
  multiPart?: Object
  data?: Object | JSON
  parseResponse?: boolean
  id?: string
  debounce?: boolean
  allowSSR?: boolean
}

type Response = {
  payload?: any
  response: any
}

/**
 * Makes web requests to the Medshr API (GET, POST only).
 *
 * @param request
 *  To post a File, request must be a FormData containing it.
 *
 * @param ssr
 *  If true, the request will be made in the server.
 *
 * @returns {Promise}
 */
const callAPI = async (
  request: Request,
  ssr: boolean = false
): Promise<Response> => {
  if (typeof window === 'undefined' && !ssr && !request.allowSSR) {
    return Promise.reject(new Error('Cannot call API SSR'))
  }

  const { method, url, headers, json, multiPart, data, parseResponse } = request

  const fetchOptions: any = {
    method: method || 'GET',
    headers: new Headers({ ...commonRequestHeaders(), ...(headers || {}) }),
    mode: 'cors',
    cache: 'default',
    credentials: 'include',
  }

  if (method === 'DELETE' || method === 'PUT') {
    fetchOptions.headers.set('x-http-method-override', method)
    fetchOptions.method = 'POST'
  }

  if (json) {
    fetchOptions.headers.set('Content-Type', 'application/json')
    fetchOptions.body = JSON.stringify(json)
  }

  if (data) {
    fetchOptions.headers.set('X-Requested-With', 'XMLHttpRequest')
    fetchOptions.body = data
  }

  const full_url = qualifyUrl(url)
  const id = request.id || full_url

  verbose && console.log('preparing', id, full_url)

  return new Promise((resolve, reject) => {
    if (request.debounce) {
      verbose && console.log('debounce', id)
      if (cancellableRequests[id]) {
        verbose && console.log('hasCancellable', id)
        if (cancellableRequests[id].url === full_url) {
          return
        } else {
          verbose && console.log('cancelling', id)
          cancellableRequests[id].reject()
          verbose && console.log('cancelled', id)
        }
      }
    }

    // use native browser implementation if it supports aborting
    const abortableFetch =
      typeof window !== 'undefined' && 'signal' in Request.prototype
        ? window.fetch
        : fetch

    const controller =
      typeof AbortController === 'undefined' ? undefined : new AbortController()

    if (multiPart) {
      let formData = new FormData()
      reduce(
        (fd, [key, value]) => {
          if (value) {
            if (
              typeof value === 'object' &&
              value.constructor.name === 'Blob'
            ) {
              fd.append(key, value)
            } else {
              fd.append(key, JSON.stringify(value))
            }
          }
          return fd
        },
        formData,
        toPairs(multiPart)
      )
      fetchOptions.body = formData
    }

    let req = new Request(full_url, {
      ...fetchOptions,
      signal: controller?.signal,
    })

    const skip = {
      401: true,
      403: true,
      404: true,
      409: true,
      410: true,
      429: true,
    }

    const res = abortableFetch(req)
      .then(res => {
        delete cancellableRequests[id]

        const response = {
          status: res.status,
          headers: res.headers,
          redirected: res.redirected,
          url: res.url,
          body: res.body,
        }

        if (/^application\/json/.test(res.headers.get('content-type'))) {
          return res
            .json()
            .then(json =>
              res.ok
                ? resolve({ payload: json, response })
                : reject({
                    payload: json,
                    response,
                    notrack: !!skip[res.status],
                  })
            )
            .catch(jsonError =>
              reject({
                error: jsonError,
                status: res.status,
                url: res.url,
                body: res.body,
              })
            )
        }
        return res.ok
          ? resolve({ payload: undefined, response })
          : reject({
              payload: undefined,
              response,
              notrack: !!skip[res.status],
            })
      })
      .catch(err => {
        const res = err.response

        if (
          res &&
          res.headers &&
          /^application\/json/.test(res.headers.get('content-type'))
        ) {
          return res
            .json()
            .then(json =>
              reject({
                payload: json,
                error: json.message || json.error || 'Unknown error',
                cancelled: err.cancelled,
                notrack: !!skip[err.status],
                status: err.status,
              })
            )
            .catch(jsonError =>
              reject({
                error: jsonError,
                cancelled: err.cancelled,
                notrack: !!skip[err.status],
                status: err.status,
              })
            )
        }

        return reject({
          error:
            err instanceof Error
              ? err
              : err.message || err.err || err.error || 'Unknown error',
          cancelled: err.cancelled,
          notrack: !!skip[err.status],
          status: err.status,
        })
      })

    if (request.debounce) {
      cancellableRequests[id] = {
        url: full_url,
        reject: () => {
          req && controller && controller.abort()
          reject({ cancelled: true, error: 'Request Cancelled' })
        },
      }
      verbose && console.log('cancellable', id, url)
    }
  })
}

export default callAPI
